import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { ITEM_ASSETS_API } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { Image } from "../../../../../Partials/Elements/Image/Image";
import { useEffect, useState } from "react";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";

function ViewItemAssets({ assetId }) {
  const axiosInstance = useAxiosInstance();
  const [error, setError] = useState("");

  const {
    info: asset,
    loading,
    error: fetchError,
  } = useGetInfo({
    axiosInstance: axiosInstance,
    api: ITEM_ASSETS_API + "singleItemAsset/" + assetId,
  });

  useEffect(() => {
    if (fetchError) {
      setError("Failed to fetch asset data. Please try again.");
    }
  }, [fetchError]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error_message">{error}</div>;
  }

  return (
    <div className="crud_view_content">
      {asset ? (
        <>
          <h1>Precedence</h1>
          <p>{asset.precedence}</p>

          <h1>Image</h1>
          <ImageCell
            imgSrc={asset.image}
            imgAlt={asset.name}
            className="item-image"
          />
        </>
      ) : (
        <div>No asset details available.</div>
      )}
    </div>
  );
}

export default ViewItemAssets;
