
import { archiveButtonDataTip } from '../../../../Utilities/HelperFunctions/archiveButtonDataTip';
import { useAxiosInstance } from '../../../../Utilities/Hooks/useAxiosInstance';
import './ArchiveItem.css'

function ArchiveItem({ api, targetID, setShowActionButton, setShowModal, triggerFetch, isActive, isArchive }) {
  const axiosInstance = useAxiosInstance();

  const buttonText = archiveButtonDataTip(isActive, isArchive)

  async function handleArchive(id) {

    let data;

    if (isActive !== undefined) {
      const updatedIsActive = !isActive;
      data = { isActive: updatedIsActive };
    } else {
      const updatedIsArchive = !isArchive;
      data = { isActive: updatedIsArchive };
    }

    const res = await axiosInstance.patch(`${api}${id}`, data);

    if (res.data) {
      setShowModal(false);
      setShowActionButton(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="delete_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to {buttonText} this.</p>
        <div className="btn_container">

          <button
            onClick={() => handleArchive(targetID)}
            className='delete'
          >{buttonText}
          </button>

          <button className='cancel'
            onClick={() => {
              setShowActionButton(false);
            }}
          >Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default ArchiveItem 