import { useState, useEffect } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Forms/Form";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { ITEM_ASSETS_API } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useGetInfo } from "antopolis-react-utils/hooks";

function UpdateItemAssets({ assetId, setShowUpdateForm }) {
  const axiosInstance = useAxiosInstance();
  const [image, setImage] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [error, setError] = useState("");
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const {
    info: asset,
    loading,
    error: fetchError,
  } = useGetInfo({
    axiosInstance: axiosInstance,
    api: ITEM_ASSETS_API + "singleItemAsset/" + assetId,
  });

  useEffect(() => {
    if (asset && !initialDataLoaded) {
      setImage(asset.image);
      setPrecedence(asset.precedence);
      setInitialDataLoaded(true);
    }
  }, [asset, initialDataLoaded]);

  async function handleSubmit(e) {
    e.preventDefault();

    const assetData = new FormData();
    assetData.append("image", image);
    assetData.append("precedence", precedence);

    try {
      const response = await axiosInstance.patch(
        ITEM_ASSETS_API + "singleItemAsset/" + assetId,
        assetData
      );
      if (response) {
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError("An error occurred while updating the asset. Please try again.");
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (fetchError) {
    return (
      <div className="error_message">
        Failed to fetch asset data. Please try again.
      </div>
    );
  }

  return (
    <div className="update_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowUpdateImage
        >
          Upload Asset Image
        </ImageInput>

        <NumberInput
          label="Precedence"
          value={precedence}
          setState={setPrecedence}
          placeholder="Enter Precedence"
        />

        <FormSubmitButton text="Update Asset" />
      </Form>
    </div>
  );
}

export default UpdateItemAssets;
