import { CHANGE_ORDERS_STATUS_API } from "../../../../../Utilities/APIs/OrderAPIs/OrderAPIs";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
function CancelOrder({
    setShowCancelOrder,
    targetID,
    triggerFetch,
    employee,
}) {
    const axiosInstance = useAxiosInstance();

    async function handleCancel(id) {
        const canceledOrder = await axiosInstance.put(
            CHANGE_ORDERS_STATUS_API + "canceledOrder/" + targetID,
            ""
        );

        if (canceledOrder) {
            setShowCancelOrder(false);
            triggerFetch();
        }
    }

    return (
        <div className="modal_delete_content">
            <p style={{ textAlign: "center" }}>
                Are you sure you want to cancel this order?
            </p>

            <div className="modal_group_buttons">
                <button className="delete" onClick={handleCancel}>
                    Delete
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowCancelOrder(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default CancelOrder;
