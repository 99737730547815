import { useContext, useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import { useGetInfo } from "antopolis-react-utils/hooks"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"

function UpdateHeroSlider({ api, setShowUpdateForm, targetID, triggerFetch }) {

    const [link, setLink] = useState('')
    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')
    const [mobileImage, setMobileImage] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()

    const { info: heroSlider } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'singleHeroSlider/' + targetID,
      })

      useEffect(() => {
        if(heroSlider) {
          setLink(heroSlider.link)
          setImage(heroSlider.image)
          setPrecedence(heroSlider.precedence)
          setMobileImage(heroSlider.mobileImage)
        }
      }, [heroSlider])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('link', link)
        itemData.append('image', image)
        itemData.append('precedence', precedence)
        itemData.append('mobileImage', mobileImage)

        const response = await axiosInstance.patch(api + 'singleHeroSlider/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Link`}
                    value={link}
                    placeholder={`Enter Link`}
                    setState={setLink}

                />
                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowUpdateImage
                >
                    Upload Image
                </ImageInput>

                <ImageInput
                    fieldId='2'
                    state={mobileImage}
                    setState={setMobileImage}
                    allowUpdateImage
                >
                    Upload Image
                </ImageInput>

                <NumberInput
                    label={`Precedence`}
                    value={precedence}
                    placeholder={`Enter Precedence`}
                    setState={setPrecedence}

                />

                <FormSubmitButton text="Update Hero Slider" />
            </Form>
        </div>
    )
}

export default UpdateHeroSlider