import { useGetInfo } from "antopolis-react-utils/hooks";
import React, { useContext, useState } from "react";
import { RiHeart2Line, RiMapPin2Line, RiShoppingBag2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import {
    CRUDButton,
    ViewButton,
} from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import ViewCustomer from "./ViewCustomer";

function Customers({ api }) {
  const navigate = useNavigate();
  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const { info: customers } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "allCustomers",
    toggleFetch,
  });
  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`Customers (${customers?.length} in total)`}</h1>
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Customer Name" />
          <CRUDth th="Image" />
          <CRUDth th="Email" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {customers &&
            customers?.map((e) => (
              <tr key={e._id}>
                <ShortTextCell text={e.name} />
                <ImageCell imgSrc={e.dp} imgAlt={e.name} />
                <ShortTextCell text={e.email} />
                <td>
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={e._id}
                      setTargetID={setTargetID}
                    />
                    <CRUDButton
                      handleClick={() =>
                        navigate("/main/wishlist_from_customer/" + e._id)
                      }
                    >
                      <RiHeart2Line />
                    </CRUDButton>
                    <CRUDButton
                      handleClick={() =>
                        navigate("/main/orders_from_customer/" + e._id)
                      }
                    >
                      <RiShoppingBag2Line  />
                    </CRUDButton>
                    <CRUDButton
                      handleClick={() =>
                        navigate("/main/addresses_from_customer/" + e._id)
                      }
                    >
                      <RiMapPin2Line />
                    </CRUDButton>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CRUDTable>
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Customer`}
          setShowModalContent={setShowViewSection}
        >
          <ViewCustomer targetID={targetID} api={api} />
        </Modal>
      )}
    </div>
  );
}

export default Customers;
