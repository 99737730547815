export const ITEMS_API = "items/";

// Control Items
export const CONTROL_ITEMS_API = ITEMS_API + "controlItems/";

// item groups
export const ITEMS_GROUP_API = ITEMS_API + "itemGroups/";
export const CATEGORIES_API = ITEMS_GROUP_API + "categories/";
export const MANAGE_CATEGORIES_API = CATEGORIES_API + "manageCategories/";

export const COLLECTIONS_API = ITEMS_GROUP_API + "collections/";
export const NAV_COLLECTION_API = ITEMS_GROUP_API + "navCollections/";
export const ITEM_COLLECTIONS_API = ITEMS_GROUP_API + "itemCollections/";

// manage items
export const MANAGE_ITEMS_API = CONTROL_ITEMS_API + "manageItem/";


export const ITEM_ASSETS_API = CONTROL_ITEMS_API + "itemAssets/";
