import React from 'react'
import { NavCardList } from '../../../Partials/Layout/NavCardList/NavCardList'
import { NavCard } from '../../../Partials/Layout/NavCardList/NavCard/NavCard'
import { RiPercentFill, RiShoppingCart2Fill } from 'react-icons/ri'
import { useGetInfo } from 'antopolis-react-utils/hooks';
import { ORDERS_API } from '../../../../Utilities/APIs/OrderAPIs/OrderAPIs';
import { useAxiosInstance } from '../../../../Utilities/Hooks/useAxiosInstance';
import { FaShippingFast } from "react-icons/fa";
import { IoIosDoneAll } from "react-icons/io";
import { FaTruck, FaDolly, FaX } from "react-icons/fa6";

const orderRoutes = '/main/ordersFromStatus/'

function OrdersScreen() {

    const axiosInstance = useAxiosInstance();

    const { info: orders } = useGetInfo({
        axiosInstance: axiosInstance,
        api: ORDERS_API + "getOrderCountsByStatus",

    });
    console.log("orders...", orders?.find(i => i.status === 'processing')?.count)
    return (
        <NavCardList numOfCards={'six'} >

            {/* <NavCard showBadge cardName={'Admin Order'} navCardLink={'/main/adminOrder'} ><RiShoppingCart2Fill /></NavCard> */}
            <NavCard showBadge number={orders?.find(i => i.status === 'processing')?.count} cardName={'Processing'} navCardLink={orderRoutes + 'processing'} ><FaDolly /></NavCard>
            <NavCard showBadge number={orders?.find(i => i.status === 'toBeDelivered')?.count} cardName={'ToBeDelivered'} navCardLink={orderRoutes + 'toBeDelivered'} ><FaTruck /></NavCard>
            <NavCard showBadge number={orders?.find(i => i.status === 'shipped')?.count} cardName={'Shipped'} navCardLink={orderRoutes + 'shipped'} ><FaShippingFast /></NavCard>
            <NavCard  number={orders?.find(i => i.status === 'completed')?.count} cardName={'Completed'} navCardLink={orderRoutes + 'completed'} ><IoIosDoneAll /></NavCard>
            <NavCard  number={orders?.find(i => i.status === 'canceled')?.count} cardName={'Canceled'} navCardLink={orderRoutes + 'canceled'} ><FaX /></NavCard>

        </NavCardList>
    )
}

export default OrdersScreen