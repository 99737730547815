import { useContext, useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import { useGetInfo } from "antopolis-react-utils/hooks"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import SwitchInput from "../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput"

function UpdateDeliveryFee({ api, setShowUpdateForm, targetID, triggerFetch }) {
    const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [isApplicable, setIsApplicable] = useState(false)

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()

    const { info: vat } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'singleDeliveryFee/' + targetID,
    })

    useEffect(() => {
        if (vat) {
            setName(vat.name)
            setAmount(vat.amount)
            setIsApplicable(vat.isApplicable)
        }
    }, [vat])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)
        itemData.append('amount', amount)
        itemData.append('isApplicable', isApplicable)

        const response = await axiosInstance.patch(api + 'singleDeliveryFee/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />
                <NumberInput
                    label={`amount`}
                    value={amount}
                    placeholder={`Enter amount`}
                    setState={setAmount}

                />
                <SwitchInput
                    label={"visibility"}
                    toggleSwitch={() => setIsApplicable((prevState) => !prevState)}
                    checked={isApplicable}
                />

                <FormSubmitButton text="Update Delivery Fee" />
            </Form>
        </div>
    )
}

export default UpdateDeliveryFee