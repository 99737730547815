import { useContext, useEffect, useState } from "react";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { CATEGORIES_API } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import slugify from "react-slugify";

function UpdateCategory({ setShowUpdateForm, targetID }) {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [error, setError] = useState("");
  console.log("image",image)

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: category } = useGetInfo({
    axiosInstance: axiosInstance,
    api: CATEGORIES_API + "/singleCategory/" + targetID,
  });

  useEffect(() => {
    if (category) {
      setName(category.name);
      setImage(category.image);
      setDescription(category.description);
      setPrecedence(category.precedence);
    }
  }, [category]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("image", image);
    itemData.append("slug", slugify(name));
    itemData.append("description", description);
    itemData.append("precedence", precedence);

    try {
      const response = await axiosInstance.patch(
        CATEGORIES_API + "/singleCategory/" + targetID,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while updating the social link. Please try again."
      );
    }
  }

  return (
    <div className="crate_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Upload Product Image
        </ImageInput>

        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />
        {/* <ShortTextInput
          label="Slug"
          value={slug}
          placeholder="Enter Slug"
          setState={setSlug}
        /> */}
        <NumberInput
          label="Precedence"
          value={precedence}
          placeholder="Enter Precedence"
          setState={setPrecedence}
        />

        <FormSubmitButton text="Update Category" />
      </Form>
    </div>
  );
}

export default UpdateCategory;
