import { useContext, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import SwitchInput from "../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput"
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import DateInput from "../../../../Partials/Forms/FormInputs/DateInput/DateInput"

function CreatePromoCode({ api, setShowCreateForm, triggerFetch }) {

    const [promoCode, setPromoCode] = useState("");
    const [maxlimit, setMaxlimit] = useState(0);
    const [discountType, setDiscountType] = useState("");
    const [discountAmount, setDiscountAmount] = useState(0);
    const [promoType, setPromoType] = useState("");
    const [validStartDate, setValidStartDate] = useState("");
    const [validEndDate, setValidEndDate] = useState("");

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = { promoCode, maxlimit, discountType, discountAmount, promoType, validStartDate, validEndDate };

        const response = await axiosInstance.post(api + 'createPromoCode', itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Promo Code`}
                    value={promoCode}
                    placeholder={`Enter Promo Code`}
                    setState={setPromoCode}
                />
                <ShortTextInput
                    label={`Maxlimit`}
                    value={maxlimit}
                    placeholder={`Enter Maxlimit`}
                    setState={setMaxlimit}
                />
                <SelectInput
                    label={`Discount Type`}
                    value={discountType}
                    setState={setDiscountType}
                >
                    <SelectOption optionValue="" optionText="Select Discount Type" />
                    <SelectOption optionValue="percentage" optionText="Percentage" />
                    <SelectOption optionValue="amount" optionText="Amount" />
                </SelectInput>
                <ShortTextInput
                    label={`Discount Amount`}
                    value={discountAmount}
                    placeholder={`Enter DiscountAmount`}
                    setState={setDiscountAmount}
                />
                <SelectInput
                    label={"Promo Type"}
                    value={promoType}
                    setState={setPromoType}
                >
                    <SelectOption optionValue="" optionText="Select Promo type" />
                    <SelectOption optionValue="unlimited" optionText="unlimited" />
                    <SelectOption optionValue="oneTime" optionText="oneTime" />
                </SelectInput>
                <DateInput
                    label={`ValidStart Date`}
                    value={validStartDate}
                    placeholder={`Enter ValidStart Date`}
                    setState={setValidStartDate}
                />
                <DateInput
                    label={`Valid EndDate`}
                    value={validEndDate}
                    placeholder={`Enter Valid EndDate`}
                    setState={setValidEndDate}
                />

                <FormSubmitButton text="Create Promo Code" />
            </Form>
        </div >
    )
}

export default CreatePromoCode