import {
  ALL_EMPLOYEE_API,
  CONTACT_INFOS_API,
  CUSTOMERS_API,
  DISCOUNT_API,
  DISCOUNT_FEE_API,
  EMPLOYEES_API,
  HERO_SLIDERS_API,
  PROMO_CODE_API,
  SOCIAL_LINKS_API,
  TESTIMONIALS_API,
  VAT_API
} from "../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";
import Dashboard from "./Dashboard/Dashboard";
import DeliveryFee from "./EcommercesSettings/DeliveryFee/DeliveryFee";
import Discount from "./EcommercesSettings/Discount/Discount";
import EcommercesSettings from "./EcommercesSettings/EcommercesSettings";
import PromoCode from "./EcommercesSettings/PromoCode/PromoCode";
import VAT from "./EcommercesSettings/VAT/VAT";
import AllItem from "./Items/ItemGroup/AllItem/AllItem";
import Category from "./Items/ItemGroup/Category/Category";
import AllCollection from "./Items/ItemGroup/Collection/AllCollection/AllCollection";
import CollectionGroup from "./Items/ItemGroup/Collection/CollectionGroup";
import ItemCollection from "./Items/ItemGroup/Collection/ItemCollection/ItemCollection";
import NavCollection from "./Items/ItemGroup/Collection/NavCollection/NavCollection";
import ItemAssets from "./Items/ItemGroup/ItemAssets/ItemAssets";
import Items from "./Items/Items";
import AdminOrder from "./Orders/AdminOrder/AdminOrder";
import OrderItems from "./Orders/OrdersFromStatus/OrderItems/OrderItems";
import OrdersFromStatus from "./Orders/OrdersFromStatus/OrdersFromStatus";
import OrdersScreen from "./Orders/OrdersScreen";
import AddressesFromCustomer from "./Users/Customers/AddressesFromCustomer/AddressesFromCustomer";
import Customers from "./Users/Customers/Customers";
import OrdersFromCustomer from "./Users/Customers/OrdersFromCustomer/OrdersFromCustomer";
import WishlistFromCustomer from "./Users/Customers/WishlistFromCustomer/WishlistFromCustomer";
import EmployeeScreen from "./Users/EmployeeScreen/EmployeeScreen";
import Employees from "./Users/EmployeeScreen/Employees/Employees";
import InviteEmployees from "./Users/EmployeeScreen/InviteEmployees/InviteEmployees";
import Users from "./Users/Users";
import ContactInfos from "./WebsiteContent/ContactInfos/ContactInfos";
import HeroSlider from "./WebsiteContent/HeroSlider/HeroSlider";
import SocialLinks from "./WebsiteContent/SocialLinks/SocialLinks";
import Testimonial from "./WebsiteContent/Testimonial/Testimonial";
import WebsiteContent from "./WebsiteContent/WebsiteContent";

const MainRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "websiteContent",
    element: <WebsiteContent />,
  },
  {
    path: "items",
    element: <Items />,
  },
  {
    path: "items/allItems",
    element: <AllItem />,
  },
  {
    path: "itemAssets/:itemId",
    element: <ItemAssets />,
  },
  {
    path: "category",
    element: <Category />,
  },
  {
    path: "collection",
    element: <CollectionGroup />,
  },
  {
    path: "collection/allCollection",
    element: <AllCollection />,
  },
  {
    path: "collection/navCollection",
    element: <NavCollection />,
  },
  {
    path: "itemCollections/:collectionID",
    element: <ItemCollection />,
  },
  {
    path: "heroSliders",
    element: <HeroSlider api={HERO_SLIDERS_API} />,
  },
  {
    path: "testimonials",
    element: <Testimonial api={TESTIMONIALS_API} />,
  },
  {
    path: "contactInfos",
    element: <ContactInfos api={CONTACT_INFOS_API} />,
  },
  {
    path: "socialLinks",
    element: <SocialLinks api={SOCIAL_LINKS_API} />,
  },
  {
    path: "ecommercesSettings",
    element: <EcommercesSettings />,
  },
  {
    path: "vat",
    element: <VAT api={VAT_API} />,
  },
  {
    path: "promoCode",
    element: <PromoCode api={PROMO_CODE_API} />,
  },
  {
    path: "discounts",
    element: <Discount api={DISCOUNT_API} />,
  },
  {
    path: "delivery-fees",
    element: <DeliveryFee api={DISCOUNT_FEE_API} />,
  },
  {
    path: "Users",
    element: <Users />,
  },
  {
    path: "employeeScreen",
    element: <EmployeeScreen />,
  },
  {
    path: "employees",
    element: <Employees api={ALL_EMPLOYEE_API} />,
  },
  {
    path: "employeeInvites",
    element: <InviteEmployees api={EMPLOYEES_API} />,
  },
  {
    path: "customers",
    element: <Customers  api={CUSTOMERS_API}/>,
  },
  {
    path: "wishlist_from_customer/:customerID",
    element: <WishlistFromCustomer  api={CUSTOMERS_API}/>,
  },
  {
    path: "orders_from_customer/:customerID",
    element: <OrdersFromCustomer  api={CUSTOMERS_API}/>,
  },
  {
    path: "addresses_from_customer/:customerID",
    element: <AddressesFromCustomer  api={CUSTOMERS_API}/>,
  },
  {
    path: "orders",
    element: <OrdersScreen />,
  },
  {
    path: "ordersFromStatus/:status",
    element: <OrdersFromStatus />,
  },
  
  {
    path: "orderItemsFromOrder/:orderID",
    element: <OrderItems />,
  },
  {
    path: "adminOrder",
    element: <AdminOrder />,
  },

];

export default MainRoutes;
