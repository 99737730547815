
import Select from "react-select";
import "./searchSelectField.css";

export default function SearchSelectField({
  label,
  options,
  value,
  setState,
  optionLabel = 'label',
  optionValue = 'value',
  search
}) {
  const formattedOptions = options?.map(option => ({
    label: option[optionLabel],
    value: option[optionValue],
  })) || [];

  const selectedOption = formattedOptions.find(option => option.value === value);

  return (
    <div className="input_group">
      <label className="input_field_label caption bold">
        <strong>{label}</strong>
      </label>
      <Select
        // isSearchable={search}
        value={selectedOption}
        options={formattedOptions}
        onChange={(selected) => setState(selected?.value || '')}
        placeholder="Select an option"
      />
    </div>
  );
}
