import { useContext, useState } from "react";
import slugify from "react-slugify";
import { useGetInfo } from "antopolis-react-utils/hooks";
import {
  CATEGORIES_API,
  MANAGE_ITEMS_API,
} from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import Form from "../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import SearchSelectField from "../../../../../Partials/Forms/FormInputs/searchSelectField/SearchSelectField";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import CustomEditor from "../../../../../Partials/Elements/CustomEditor/CustomEditor";
import SwitchInput from "../../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";

function CreateItem({ setShowCreateForm }) {

  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState("");
  const [error, setError] = useState("");

  const { triggerFetch, toggleFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: categories } = useGetInfo({
    axiosInstance: axiosInstance,
    api: CATEGORIES_API + "getAllCategories",
    toggleFetch,
  });
  const { info: discounts } = useGetInfo({
    axiosInstance: axiosInstance,
    api: "ecommerceSettings/discounts/" + "getAllDiscounts",
    toggleFetch
  });
  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    // itemData.append("category", category._id);
    itemData.append("category", category);

    itemData.append("name", name);
    itemData.append("image", image);
    itemData.append("slug", slugify(name));
    itemData.append("description", description);
    itemData.append("price", price);
    itemData.append("quantity", quantity);
    itemData.append("isDiscount", isDiscount);
    isDiscount && itemData.append("discount", discount);

    console.log(itemData);

    try {
      const response = await axiosInstance.post(
        MANAGE_ITEMS_API + "createItem",
        itemData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError("An error occurred while creating the item. Please try again.");
    }
  }
  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Upload Product Image
        </ImageInput>

        <SearchSelectField
          label="Categories"
          value={category}
          optionLabel="name"
          optionValue="_id" 
          options={categories}
          placeholder="Enter Category"
          setState={setCategory}
        />

        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />

        <CustomEditor label="Description" setState={setDescription} />

        <NumberInput
          label="Price"
          value={price}
          setState={setPrice}
          placeholder="Enter Price"
        />
        <NumberInput
          label="Quantity"
          value={quantity}
          setState={setQuantity}
          placeholder="Enter Quantity"
        />
        <SwitchInput
          label={"IsDiscount"}
          toggleSwitch={() => setIsDiscount((prevState) => !prevState)}
          checked={isDiscount}
        />
        {isDiscount && <SearchSelectField
          label="Discount"
          optionLabel="amount"
          optionValue="_id"
          value={discount}
          options={discounts}
          placeholder="Enter Discounts"
          setState={setDiscount}
        />}
        <FormSubmitButton text="Create Item" />
      </Form>
    </div>
  );
}

export default CreateItem;
