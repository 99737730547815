import React from "react";
import { ScreenHolder } from "../../../Partials/Layout/ScreenHolder/ScreenHolder";
import { NavCardList } from "../../../Partials/Layout/NavCardList/NavCardList";
import { NavCard } from "../../../Partials/Layout/NavCardList/NavCard/NavCard";
import { RiCollageFill, RiGridFill } from "react-icons/ri";

function Items() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={"three"}>
        <NavCard cardName={"ALL ITEMS"} navCardLink="/main/items/allItems">
          <RiGridFill />
        </NavCard>
        <NavCard cardName={"CATEGORY"} navCardLink="/main/category">
          <RiGridFill />
        </NavCard>
        <NavCard cardName={"COLLECTION"} navCardLink="/main/collection">
          <RiCollageFill />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default Items;
