import { useContext, useState } from "react";

import { CATEGORIES_API } from "../../../../../../Utilities/APIs/ItemsAPIs/ItemsAPIs";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import slugify from "react-slugify";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";

function CreateCategory({ setShowCreateForm }) {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const [error, setError] = useState("");

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("image", image)


    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("image", image);
    itemData.append("slug", slugify(name));
    itemData.append("description", description);
    itemData.append("precedence", precedence);

    try {
      const response = await axiosInstance.post(
        CATEGORIES_API + "createCategory",
        itemData
      );
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      setError(
        "An error occurred while creating the category. Please try again."
      );
    }
  }

  return (
    <div className="create_form">
      {error && <div className="error_message">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Upload Product Image
        </ImageInput>
        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
        />
        <ShortTextInput
          label="Description"
          value={description}
          placeholder="Enter Description"
          setState={setDescription}
        />
        {/* <ShortTextInput
          label="Slug"
          value={slugify(slug)}
          placeholder="Enter Slug"
          setState={setSlug}
        /> */}
        <NumberInput
          label="Precedence"
          value={precedence}
          placeholder="Enter Precedence"
          setState={setPrecedence}
        />

        <FormSubmitButton text="Create Category" />
      </Form>
    </div>
  );
}

export default CreateCategory;
